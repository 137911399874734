<template>
	<!-- Projects Table Column -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">{{ titleTable }}</h5>
				</a-col>
			</a-row>
		</template>

		<table class="table">
			<div class="thead-table">
				<tr class="tr-v-for-column" v-for="(item, index) in columns" :key="index">
					<th class="item-th">
						{{ item.title }}
					</th>
				</tr>
			</div>

			<div class="container-body-table">
				<tr class="tr-v-for-body" v-for="(item, index) in data" :key="index">
					<th class="item-th">{{ item.title }}</th>
					<th class="item-th-actions">
						<div class="item-action">Ver</div>
						<div class="item-action">Editar</div>
						<div class="item-action">Eliminar</div>



					</th>
				</tr>
			</div>
		</table>		
	</a-card>
</template>

<script>

export default ({
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			default: () => [],
		},
		titleTable: {
			type: String,
			default: () => "",
		}
	},
	data() {
		return {
		}
	},
})

</script>